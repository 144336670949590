// Developed By O3DEVELOPERS
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

import { EncryptStorage } from 'encrypt-storage';

const storageKey = "O3developer";
let mode = "live";
export const baseURL = mode === "test" ? "http://127.0.0.1:8000" : "https://server.wakasub.com";
// Site COnfig
export const siteName = "Wakasub";
export const siteLogo = "/dashboard/images/wakasub.jpg"
export const backgroundColor = "linear-gradient(to right, rgb(3 27 154), rgb(0 5 35))";
export const backgroundWithOpacity = "linear-gradient(to right, rgb(3 27 154 / 64%), rgb(0 5 35 / 63%))";

export function scrollToTop() {
    window.scrollTo(0,0);
}

export const storage = (action, key, data, storageType) => {
    const encryptStorage = new EncryptStorage(storageKey, {storageType: storageType,});
    if (action === "save") {
        encryptStorage.setItem(key, data);
         return true;
    } else if (action == "retrieve") {
        try {
            let item = encryptStorage.getItem(key);
            if (item) return item;
        } catch(e) {
            return false;
        }
    } else if (action == "remove") {
        encryptStorage.removeItem(key); 
        return true;
    } else {
        return "No action specified";
    }
}

export const auth = () => {
   var data = storage("retrieve", "data", null);
   if (typeof(data) !== "object") return false;
   if (data == undefined || data == "") return false;
    if(data.token !== "" && data.token !== null) return true;
}

export const user = () => {
    if (auth() == true) {
        var data = storage("retrieve", "data", null);
        return data;
    }
}

export const date = (value) => {
    var obj = new Date(value);
    var formattedDate = obj.toDateString();
    var hour = obj.getHours();
    var minute = obj.getMinutes();
    var meanTime = hour >= 12 ? 'pm' : 'am';
    return formattedDate + ' | ' + hour +':'+ minute +':'+ obj.getSeconds() + ' ' + meanTime;
  }  

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function decimalCheck(n) {
    var result = (n - Math.floor(n)) !== 0; 
    if (result)
        return true;
    else {
     return false;
    }
}

export const toaster = (type, message) => {
    var prop = {                 
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'colored',
            progress: undefined,
            // icon: "🚀",
          }
    if (type == "success") {
        toast.success(message, prop);

    } else if (type == "error") {
        toast.error(message, prop);          
    }
}

export const logOff = () => {
    if(storage("remove", "data", null, "localStorage")) {
        return window.location.href="/login";
    }
}


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  


export function sweetalert(notification) {

    let notify = sessionStorage.getItem('notify');
    // notify = notify == null ? "true" : "false";
    notify = notify == null ? "true" : "true";
    // console.log(typeof(notify));
    if (notify == "true") {

    // Toast.fire({
    //     icon: 'success',
    //     title: "Good day, "+ user().username +" I'm glad you're back at O3developers."
    //   })
    
    //   return;

    Swal.fire({
        // titleText: 'Notification',
        // icon: 'success',
        // text: 'O3developers.',
        allowOutsideClick: false,
        // showClass: {
        //     popup: 'animate__animated animate__fadeInDown'
        // },
        // hideClass: {
        //     popup: 'animate__animated animate__fadeOutUp'
        // },
        html: 
        "<p class='mt-2 mb-2 font-20' style=\"font-family: 'Nunito', sans-serif\"><b>Notification </b></p> <p class='mt-2 mb-0 font-15' style=\"line-height: 25px;\"'>" + notification + "</p>"+
        "<div class='mt-2 font-15'> &copy <span style=\"font-family: 'Monoton', cursive;\">W</span><span style=\"font-family: 'Playball', cursive;\">akasub</span> </div>",
        background: '#fff url(/dashboard/images/)',
        backdrop: `
        rgba(0,0,123,0.4)
        url(null)
        left top
        no-repeat
    `,
        showDenyButton: false,
        showConfirmButton: false,            
        showCancelButton: true,
        cancelButtonText: "Okay 👍",
        cancelButtonColor: "#198754",
        denyButtonText: "Read Later ☹️",
        denyButtonColor: "#ff0000",

    }).then((result) => {
        // isDenied ==> Read Later
        if(!result.isDenied){
            sessionStorage.setItem('notify', false);
        } else {
            sessionStorage.setItem('notify', true);
        }

    });
    }

      

}

